var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "markets-ui-container",
    {
      class: _vm.namespace(),
      attrs: {
        skin: _vm.watchedSettings.skin,
        errorMessage: _vm.errorMessage,
        showBorder: _vm.watchedSettings.showBorder,
        showHeader: _vm.watchedSettings.showHeader,
        showSetting: false,
        showLoading: _vm.showLoading
      }
    },
    [
      _c(
        "span",
        {
          class: _vm.namespace("header__bold"),
          attrs: { slot: "title" },
          slot: "title"
        },
        [_vm._v("\n        " + _vm._s(_vm.initedLabels.gmbTitle) + "\n    ")]
      ),
      _vm._v(" "),
      !_vm.errorMessage
        ? _c(
            "div",
            {
              class: _vm.namespace("content"),
              attrs: { slot: "content" },
              slot: "content"
            },
            [
              _c("section", {
                ref: "salModuleWrapper",
                class: _vm.namespace("wrapper")
              })
            ]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }