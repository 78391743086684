<template>
    <markets-ui-container
        :class="namespace()"
        :skin="watchedSettings.skin"
        :errorMessage="errorMessage"
        :showBorder="watchedSettings.showBorder"
        :showHeader="watchedSettings.showHeader"
        :showSetting="false"
        :showLoading="showLoading"
    >
        <span slot="title" :class="namespace('header__bold')">
            {{ initedLabels.gmbTitle }}
        </span>
        <div slot="content" :class="namespace('content')" v-if="!errorMessage">
            <section
                :class="namespace('wrapper')"
                ref="salModuleWrapper"
            ></section>
        </div>
    </markets-ui-container>
</template>
<script>
import $ from 'jquery';
import 'jquery-migrate';
import mwcMarketsCore from 'mwc-markets-core';
import labels from './assets/labels.json';
const { utils, mixins } = mwcMarketsCore;
export default {
    name: 'mwc-markets-gmb',
    props: {},
    mixins: [mixins.MwcVueHelperMixin, mixins.component],
    data() {
        return {
            defaultConfig: {
                settings: {
                    autoHeight: false,
                    showHeader: false,
                    showBorder: false,
                    salBundleHost: null,
                    salServiceHost: null,
                    gmb: {
                        showHeader: false
                    },
                    languageId: 'en-US',
                    oauth2Token: null,
                    realTimeToken: null
                },
                labels,
                intlNamespace: 'mwc-markets-gmb'
            },
            watchedSettings: {},
            showLoading: false
        };
    },
    computed: {
        initedLabels() {
            return this.mergeLabels(labels);
        }
    },
    created() {
        this.namespace = utils.namespace('gmb');
        this.guid = utils.guid();
        this.SALModule = {
            id: `sal-components-gmb-${this.guid}`,
            name: 'sal-components-gmb'
        };
        Object.keys(this.settings).forEach(key => {
            this.$set(this.watchedSettings, key, this.settings[key]);
        });
        const { bundle } = this.getSALPaths({
            bundle: this.watchedSettings.salBundleHost,
            service: this.watchedSettings.salServiceHost
        });
        this.deps = ['sal-components'].map(
            c => `${bundle}sal-report-bundle/dist/${c}-bundle.min.js`
        );
        this.SALApplication = null;
        this.setMWCSALModuleConfig();
        this.throttleMountSALModule = utils.throttle(() => {
            this._mountSALModule();
        }, 100);
    },
    mounted() {
        if (!this.mktdata) {
            return;
        }
        utils.resizeObserver.observe(this.$el, this.resize);
        this.$wrapper = $(this.$refs.salModuleWrapper);
        this.showLoading = true;
        if (
            !this.watchedSettings.realTimeToken ||
            !this.watchedSettings.oauth2Token
        ) {
            utils.tokenManager
                .setHandler(() => {
                    return this.mktdata.queryPermission({
                        fields: 2048
                    });
                })
                .subscribe(this._tokenReadyCallback);
        } else {
            this.loadSALModule();
        }
    },
    beforeDestroy() {
        utils.tokenManager.unsubscribe(this._tokenReadyCallback);
        utils.resizeObserver.unobserve(this.$el, this.resize);
    },
    methods: {
        _tokenReadyCallback(res) {
            if (res instanceof Error) {
                this._log('ERROR', 'Failed to retrive the tokens');
                this._setError(this.ERROR_TYPES.SERVICE);
            } else {
                this.watchedSettings.realTimeToken =
                    this.watchedSettings.realTimeToken || res.realtime;
                this.watchedSettings.oauth2Token =
                    this.watchedSettings.oauth2Token || res.oauth2;
                this.loadSALModule();
            }
        },
        resize() {
            if (this.SALApplication) {
                this.$nextTick(() => {
                    const element = window.angular.element(
                        this.$wrapper.find('.wrapper-barometer-map')[0]
                    );

                    if (element.length) {
                        const scope = element.scope();
                        if (scope) {
                            scope.$broadcast('SALModule.windowResize');
                        }
                    }
                });
            }
        },
        loadSALModule() {
            if (this.isSALModuleDepsReady()) {
                this.mountSALModule();
            } else {
                this.deps.forEach(src => {
                    utils.loadJSScript(
                        src,
                        () => {
                            this.mountSALModule();
                        },
                        () => {
                            this.mountSALModule();
                        }
                    );
                });
            }
        },
        isSALModuleDepsReady() {
            return (
                this.deps.filter(src => !utils.isJSResponseReceive(src))
                    .length === 0
            );
        },
        mountSALModule() {
            if (this.isSALModuleDepsReady()) {
                this.throttleMountSALModule();
            }
        },
        _mountSALModule() {
            this.$wrapper
                .empty()
                .append(
                    $(
                        `<section class="${this.namespace('sal-module')}"><${
                            this.SALModule.name
                        } mwc-id="${this.SALModule.id}"></${
                            this.SALModule.name
                        }></section>`
                    )
                );
            this.SALApplication = window.angular.module('GMB', ['SALModule']);
            this.setSALModuleValues();
            window.angular.bootstrap(
                window.angular.element(
                    this.$wrapper.find(`.${this.namespace('sal-module')}`)
                ),
                ['GMB']
            );
            this.showLoading = false;
        },
        setSALModuleValues() {
            if (this.SALApplication) {
                const { service } = this.getSALPaths({
                    bundle: this.watchedSettings.salBundleHost,
                    service: this.watchedSettings.salServiceHost
                });
                const { realTimeToken, oauth2Token } = this.watchedSettings;
                this.SALApplication.value('REALTIME_TOKEN', realTimeToken)
                    .value('ACCESS_TOKEN', oauth2Token)
                    .value('serviceBase', `${service}/sal-service/v1/`);
            }
        },
        setMWCSALModuleConfig() {
            const components = {};
            components[this.SALModule.id] = {
                type: this.SALModule.name,
                settings: utils.extend(
                    {
                        languageId: this.watchedSettings.languageId
                    },
                    this.watchedSettings.gmb
                )
            };
            if (window.mwc) {
                window.mwc.configuration.setAppConfig({
                    components
                });
            }
        },
        _setError(error) {
            this.error = error;
            this.showLoading = false;
        }
    }
};
</script>
<style lang="scss">
.mwc-markets-gmb {
    &-content {
        height: 100%;
    }
    &-wrapper {
        position: relative;
        height: 100%;
    }
    &-sal-module {
        overflow: auto;
        width: 100%;
        height: 100%;
        .barometer-map * {
            box-sizing: content-box;
        }
    }
}
</style>
