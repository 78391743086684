(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("mwc-markets-core"), require("jquery"), require("jquery-migrate"));
	else if(typeof define === 'function' && define.amd)
		define(["mwc-markets-core", "jquery", "jquery-migrate"], factory);
	else if(typeof exports === 'object')
		exports["mwcMarketsGmb"] = factory(require("mwc-markets-core"), require("jquery"), require("jquery-migrate"));
	else
		root["mwcMarketsGmb"] = factory(root["mwcMarketsCore"], root["jQuery"], root["jQuery"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__8__, __WEBPACK_EXTERNAL_MODULE__22__, __WEBPACK_EXTERNAL_MODULE__77__) {
return 